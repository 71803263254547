import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Zhylybuly from './pages/Zhylybuly';
import PayForm from './pages/PayForm';
import Payment from './pages/Payment';
import Order from './pages/Order';
import Terms from './components/Terms';
import ContactPage from './pages/ContactPage';
import Logos from './pages/Logos';
import PlansPage from './pages/PlansPage';
import BlogForm1 from './pages/BlogForm1';

function App() {
  return (
    <Router>
        <div>
          <Routes>
            <Route path = "/purchase/:id" element={<PayForm title="ShkolaKazok Purchase"  />}/>
            <Route path = "/payment/:id" element={<Payment title="ShkolaKazok Payment"  />}/>
            <Route path = "/order" element={<Order title="ShkolaKazok Order"  />}/>
            <Route path = "/terms/offer" element={<Terms field="offer"/>}/>
            <Route path = "/terms/about" element={<Terms field="about"/>}/>
            <Route path = "/terms/payment" element={<Terms field="payment"/>}/>
            <Route path = "/terms/privacy" element={<Terms field="privacy"/>}/>
            <Route path = "/contact" element={<ContactPage/>}/>
            <Route path = "/logos" element={<Logos/>}/>
            <Route path = "/catalog" element={<PlansPage/>}/>
            <Route path = "/anna_sapena/anketa" element={<BlogForm1/>}/>
            <Route exact path="/" element={<Zhylybuly title="ShkolaKazok" />}/>
          </Routes>
        </div>
      </Router>
  );
}

export default App;
