import Container from "react-bootstrap/esm/Container";
import { useWindowSize } from "./WindowSize";

function Instructions() {
    const st = {margin: "18px auto", padding: "4px 8px", borderTopRightRadius: "16px", borderBottomRightRadius: "16px",
        borderLeft: "thick solid rgb(222, 136, 86)", borderTop: "thin solid rgb(222, 136, 86)",
        borderRight: "thin solid rgb(222, 136, 86)", borderBottom: "thin solid rgb(222, 136, 86)"};
    const [width, ] = useWindowSize();
    if (width < 600) {
        st["width"] = "90%";
        st["fontSize"] = "smaller";
    }
    return (<Container className="ohidden" style={st}>
        <p style={{margin: "0px"}}>Після оплати, ми, тренери ШКОЛИ, <b>зв'яжемося</b> з вами та <b>додамо у закриті телеграм групу та канал.</b></p>
        <p style={{margin: "0px"}}>Далі ми будемо з вами <b>на постійному зв'язку</b> та ви отримаєте усі необхідні інструкції.</p>
    </Container>);
}

export default Instructions;