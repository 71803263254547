import { Container } from "react-bootstrap"
import { useState, useEffect } from "react";

function Terms({field}) {
    const title = {"offer": "Довір публічної оферти", 
        "about": "Про нас", 
        "payment": "Ціна послуг та порядок розрахунку", 
        "privacy": "Політика конфіденційності Magistrika"}
    const [msg, setMsg] = useState([]);
    
    useEffect(() => {
        fetch('/terms/' + field + '.txt')
        .then((r) => r.text())
        .then(text  => {
          const lines = text.split('\n');
          setMsg(lines);
        });
    }, [field]);

    return <div>
        <div className="headerDiv">
            <h2 style={{margin: "0px", color: "rgb(245, 235, 194)"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА</h2>
        </div>
        <Container>
            <h3 style={{color: "black", fontSize: "x-large", marginTop: "12px"}}>{title[field]}</h3>
            <hr/>
            {msg.map(function(line, index) {
                return index === 0 ? <p style={{fontWeight: "600"}} key={index}>{line.trimRight()}</p> :
                    <p key={index}>{line.trimRight()}</p>
            })}
        </Container>
    </div>
}

export default Terms