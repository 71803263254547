import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import { PayFormLogo } from "./PayForm";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ImgWithFallback from "../components/ImgWithFallback";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import { useSearchParams } from "react-router-dom";
import React from "react";
import { ThreeDots } from  'react-loader-spinner';
import { constPACKAGES } from "../components/courseConstants";

function Circle(props) {
    const src = "../zhy/" + props.color + "ball.";
    return (
        <>
            <div>
                <ImgWithFallback src={src + "webp"} fallbackSrc={src + "png"} className="w100" />
            </div>
            <div className="centeredInImg">
                {props.children}
            </div>
        </>);
}

function getAPICall(apiFnc, params, setFnc, errorFnc = null) {
    const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        }
    };
    var url = 'https://magistrika.com/' + apiFnc + '.php'; 
    if (params.size !== 0) {
        var sep = '?';
        for (const [key, value] of Object.entries(params)) {
            url += sep + key + '=' + value;
            sep = '&';
        };
    }
    fetch(url, options)
        .then(response => response.json())
        .then(data => {
            setFnc(data);
        })
        .catch(error => {
            if (errorFnc !== null) errorFnc(error);
        });
}

class PixelPayForm extends React.Component {
    componentDidMount() {
        if (this.props.order !== undefined && ("plan" in this.props.plan) && ("price" in this.props.plan)) {
            ReactPixel.track('Purchase', {content_ids: [constPACKAGES[this.props.plan["plan"] - 1]], content_type: "product",
                value: this.props.plan["price"], currency: "UAH"});
        }
    }
  
    render() {
      return <></>
    }
}  

function Payment({title}) {
    const { id } = useParams(); // thankyou or decline
    const [searchParams, ] = useSearchParams();
    const [plan, setPlan] = useState({});
    var order = searchParams.has("order") ? searchParams.get("order") % 1000000 : undefined;
    const [loading, setLoading] = useState(() => { return order !== undefined; });

    useEffect(() => {
        const updatePlan = (data) => {
            if (Object.keys(plan).length === 0) {
                setPlan(data);
            }
            setLoading(false);
        }
        document.title = title;
        ReactPixel.init("206064184014090");
        ReactPixel.pageView();
        if (order !== undefined) {
            getAPICall('getplan', {order: order}, updatePlan, updatePlan);
        }
    }, [title, order, plan]);
    
    const ok = id === "thankyou";
    const imgs = ok ? "check" : "sad"; 
    const header = ok ? "Дякуємо! Ваше замовлення прийнято." : "Вам не вдалося сплатити?";
    const sub = ok ? <h4>Ми неймовірно раді, що ви стали частиною нашої спільноти.</h4> : 
        <>
        <h4>Будь ласка, зв'яжіться з нами:</h4>
        <p><a href="https://t.me/AnnaSapena">Анна Сапена</a> або <a href="https://t.me/Anastasiya_chudoYudina">Анастасія Юдіна</a> та ми допоможемо вам долучитися до нашої спільноти.</p>
        <hr/>
        <h4>Чи безпечно сплачувати вводячи данні своєї картки?</h4>
        <p>Ми підключили сервіс WayForPay для вашої зручності. Це повністю безпечно, адже данні вашої картки не отримує сервіс і, тим паче, ми. Вас лише переводить на строінку банк-клієнта для підтвердження платежу.</p>
        </>;
    const sub2 = ok ? <><p style={{marginBottom: "0px"}}>Скоро ми зв'яжемося з вами та додамо у закриті телеграм групу та канал.</p>
        <p>Далі ми будемо з вами на постійному зв'язку та ви отримаєте усі необхідні інструкції.</p></> : 
        <></>;
    const iconst = {maxWidth: "40%", marginTop: "12px", marginBottom: "12px"};
    const lasticonst = {maxWidth: "40%", marginBottom: "12px"};    
    return loading ?
        <div style={{width: "100vw", minHeight: "100vh", backgroundColor: "#000000a6", zIndex: "100",
            left: "0px", top: "0px", position: "fixed", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <ThreeDots height="24" color="white" />
        </div>
        :
         (
        <div style={{minHeight: "100vh", backgroundColor: "antiquewhite"}}>
        <Container style={{background: "white", paddingTop: "24px", paddingBottom: "24px", borderRadius: "8px", 
          transform: "translateY(34px)", boxShadow: "3px 4px 20px 0px #a38e73"}}>
            <PayFormLogo />
            { ok && <PixelPayForm order={order} plan={plan} /> }
            <Row style={{alignItems: "center", textAlign: "center"}}>
                <Col xs={2}>
                    <ImgWithFallback src={"../zhy/" + imgs + ".webp"} fallbackSrc={"../zhy/" + imgs + ".png"}
                        style={{width: "100%", maxWidth: "64px"}} />
                </Col>
                <Col style={{textAlign: "left"}}>
                    <h2>{header}</h2>
                </Col>
            </Row>
            <Row style={{textAlign: "center", marginTop: "16px"}}>
                <hr />
                {sub}
            </Row>
            <Row style={{textAlign: "center", marginTop: "16px"}}>
                <hr />
                {sub2}
            </Row>
            <Row style={{textAlign: "center", marginTop: "16px"}}>
                <div><Link to="..">
                    <Button variant="custom" className="mybutton" style={{marginBottom: "8px"}}>Назад</Button>
                </Link></div>
                <p><i>В мене є інші запитання, куди написати?</i></p>
            </Row>
            <div style={{backgroundColor: "white", margin: "0px 8px 8px 8px", borderRadius: "12px", borderColor: "#fed065",
                borderStyle: "outset", textAlign: "center", paddingTop: "8px"}}>
            <Row>
                <Col style={{position: "relative"}}>
                    <div style={{maxWidth: "150px", margin: "auto"}}>
                        <Circle color="yellow">
                            <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Інстаграм спільнота</h4>
                        </Circle>
                    </div>
                </Col>
                <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "150px", margin: "auto"}}>
                            <Circle color="green">
                                <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Канал терапевтичних казок</h4>
                            </Circle>
                        </div>
                    </Col>
                    <Col style={{position: "relative"}}>
                        <div style={{maxWidth: "150px", margin: "auto"}}>
                            <Circle color="pink">
                                <h4 style={{fontWeight: "bold", marginLeft: "25%", marginRight: "25%"}}>Сторінки тренерів</h4>
                            </Circle>
                        </div>
                    </Col>
            </Row>
            <Row>
                <Col style={{position: "relative"}}>
                    <div style={{maxWidth: "200px", margin: "auto"}}>
                        <a href="https://instagram.com/zhyly.buly" target="_blank" rel="noopener noreferrer">
                            <img src="../zhy/instagram.png" alt="" width="49px" style={iconst} />
                        </a>
                    </div>
                </Col>
                <Col style={{position: "relative"}}>
                    <div style={{maxWidth: "200px", margin: "auto"}}>
                        <a href="https://t.me/Kazki_AnnaSapena" target="_blank" rel="noopener noreferrer">
                            <img src="../zhy/telegram.png" alt="" width="49px" style={iconst} />
                        </a>
                    </div>
                </Col>
                <Col style={{position: "relative", display: "flex", alignItems: "center"}}>
                    <div style={{width: "50%"}}>
                        <p style={{margin: "0px", fontSize: "max(1.25vw, 9px)"}}>Анна</p>
                        <a href="https://www.instagram.com/anna.sapena" target="_blank" rel="noopener noreferrer">
                            <img src="../zhy/instagram.png" alt="" width="49px" style={lasticonst} />
                        </a>
                    </div>
                    <div style={{width: "50%"}}>
                        <p style={{margin: "0px", fontSize: "max(1.25vw, 9px)"}}>Анастасія</p> 
                        <a href="https://instagram.com/nastiny_skazki" target="_blank" rel="noopener noreferrer">
                            <img src="../zhy/instagram.png" alt="" width="49px" style={lasticonst} />
                        </a>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container style={{height: "52px"}} />
        </div>
    );
}

export default Payment;