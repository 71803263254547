import { Link } from "react-router-dom";
import { useRef } from "react";
import { Plans } from "./Zhylybuly";

function PlansPage() {
    const payRef = useRef(null);
    return (
        <>
            <div className="headerDiv">
                <h2 style={{margin: "0px", color: "rgb(245, 235, 194)"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА</h2>
            </div>
            <Plans element={payRef} />
            <div style={{backgroundColor: "white", textAlign: "center", fontSize: "x-small", borderTop: "inset thin #f9c2b1"}}>
                <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginTop: "4px"}}>
                    <Link className="mylink" to="/terms/about">ПРО НАС</Link>
                    <Link className="mylink" to="/terms/offer">ОФЕРТА</Link>
                    <Link className="mylink" to="/terms/payment">ОПЛАТА</Link>
                    <Link className="mylink" to="/terms/privacy">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</Link>
                    <Link className="mylink" to="/contact">КОНТАКТИ</Link>
                </div>
                <div style={{textAlign: "left"}}>
                    <img src="/terms/visa.svg" alt="visa" width={52} style={{marginRight: "6px"}}/>
                    <img src="/terms/mastercard.svg" alt="mastercard" width={36}  style={{marginRight: "6px"}}/>
                    <Link className="mylink" to="/terms/payment">Більше інформації</Link>
                </div>
                <p style={{margin: "0px", paddingBottom: "8px"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА, 2022.</p>
            </div>
        </>);
}

export default PlansPage