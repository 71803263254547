import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input'
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from 'react-phone-number-input' 
import { constHOME } from "../components/courseConstants";
import useDownloader from 'react-use-downloader';

function BlogForm1() {
    const [phoneInput, setPhoneInput] = useState("");
    const [formError, setFormError] = useState(0);  // 0: no error, 1: tel, 2: empty options, 3, 4: empty text, 5 rep.tel.
    const [answers, setAnswers] = useState(["", "", "", ""]);
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [sent, setSent] = useState(false);
    const [downloadState, setDownloadState] = useState(0);
    const { download, percentage, isInProgress, error } = useDownloader({mode: 'cors',});
    
    useEffect(() => {
        if (error) {
            setDownloadState(0);
            alert('Помилка завантаження файлу');
        } else if (isInProgress) {
            if (downloadState === 0) setDownloadState(1);
        } else if (downloadState === 1) setDownloadState(2);
    }, [isInProgress, downloadState, error]);

    const scrollToId = (id) => {
        var element = document.getElementById(id);
        element.scrollIntoView();
    }

    const formSubmit = (evnt) => {
        evnt.preventDefault();
        if (!isValidPhoneNumber(phoneInput)) {
            setFormError(1);
            scrollToId("sectionPhone");
        } else {
            var filled = true;
            for (var i = 0; i < answers.length; i++) {
                if (answers[i].trim() === "") {
                    filled = false;
                    break;
                }
            }
            if (!filled) setFormError(2);
            else if (answers[1].includes("7") && text1.trim() === "") { 
                setFormError(3);
                scrollToId("q1_7");
            } else if (answers[2].includes("6") && text2.trim() === "") {
                setFormError(4);
                scrollToId("q2_6");
            } else {
                setFormError(0);
                var data = answers[0] + '|' + answers[1] + '|' + answers[2] + '|' + answers[3];
                const t1 = text1.trim().replace(/\|/g, '｜').replace(/'/g, '’');
                data += "|" + t1;
                const t2 = text2.trim().replace(/\|/g, '｜').replace(/'/g, '’');
                data += "|" + t2;
                const url = constHOME + "sendForm.php?data=" + encodeURIComponent(data) +
                    "&tel=" + encodeURIComponent(phoneInput);
                fetch(url).then(res => res.json())
                    .then((result) => {
                        if ('res' in result && result['res'] === "ok") {
                            setSent(true);
                            scrollToId("sectionBanner")
                        } else {
                            setFormError(5);
                            scrollToId("sectionPhone");
                        }
                    },
                    (error) => { setFormError(5); scrollToId("sectionPhone"); })
            }
        }
    }

    const setPhoneValue = (inputValue) => {
        const p = inputValue || "";
        setPhoneInput(p);
    }
  
    function FormError({error, text}) {
        return error ? 
          <p style={{color: 'red', fontSize: 'small'}}>{text}</p>
          : <></>
    }

    const changeOneValid = (number, option) => {
        const o = option.toString();
        const nextAnswers = answers.map((ans, i) => {
            if (i === number) return ans === o ? "" : o;
            else return ans;
          });
          setAnswers(nextAnswers);
    } 

    const changeMultiple = (number, option) => {
        const o = option.toString();
        const nextAnswers = answers.map((ans, i) => {
            if (i === number) return ans.includes(o) ? ans.replace(o, '') : ans.concat(o);
            else return ans;
          });
          setAnswers(nextAnswers);
    } 
    
    const downloadFile = (id) => {
        const url = constHOME + "downloadFile.php?id=" + id + "&tel=" + encodeURIComponent(phoneInput);
        var filename;
        if (id === 1) filename = "ГАЙД МЕДИТАТИВНА КАЗКА.pdf";
        else if (id === 2) filename = "ПУТІВНИК_ЯК_ЗАРОБЛЯТИ_НА_КАЗКАХ.pdf";
        else filename = "КАЗКА ЧАРІВНА КНИГА.pdf";
        download(url, filename);
        scrollToId("progressSection");
    }

    return <div>
        <img id="sectionBanner" src="/zhy/blog/banner.jpg" alt="" style={{width: "100vw"}}/>    
        <div style={{padding: "24px"}}>
            <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                <img src="/zhy/blog/logo.png" alt="" style={{width: "140px", maxWidth: "25%", marginRight: "8px"}}/>
                <h3 style={{fontWeight: "600"}}>КАЗКОВА АНКЕТА ДЛЯ МОЇХ ПІДПИСНИКІВ (ІЗ ПОДАРУНКОМ 🎁)</h3>
            </div>
            {!sent ? <>
            <p>Вітаю вас, мої любі друзі! Я щиро вдячна вам за те, що ви подорожуєте зі мною у моєму блозі <a href="https://www.instagram.com/anna_sapena/" target="_blank" rel="noreferrer">@Anna_Sapena</a> у світ літератури та творчості. Ви надихаєте мене робити цей світ яскравішим та багатшим.<br/> 
            Ваші коментарі, питання, реакції допомагають мені ділитися корисною та цікавою інформацією.</p>
            <p>Аби я могла й надалі надавати вам цінний контент, ділитися порадами та інсайтами про творчість, видавництво книг і створення казок, прошу пройти цю анкету.</p>
            <hr/>
            <Form>
                <Form.Group className="mb-3" controlId="formq0">
                    <Form.Label style={{fontWeight: "600"}}>Вкажіть ваш вік:</Form.Label>
                    <Form.Check type="radio" label="до 18" name="age" id="q0_1" onChange={() => { changeOneValid(0, 1); }}/>
                    <Form.Check type="radio" label="19-25" name="age" id="q0_2" onChange={() => { changeOneValid(0, 2); }}/>
                    <Form.Check type="radio" label="26-48" name="age" id="q0_3" onChange={() => { changeOneValid(0, 3); }}/>
                    <Form.Check type="radio" label="49+" name="age" id="q0_4" onChange={() => { changeOneValid(0, 4); }}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formq1">
                    <Form.Label style={{fontWeight: "600"}}>Що вас цікавить в моєму блозі найбільше (можете обрати декілька варіантів):</Form.Label>
                    <Form.Check type="checkbox" label="Казкотерапія / терапевтичні казки / книги для дітей."
                        name="like" id="q1_1" onChange={() => { changeMultiple(1, 1); }}/>
                    <Form.Check type="checkbox" label="Видання книг / письменницькі поради."
                        name="like" id="q1_2" onChange={() => { changeMultiple(1, 2); }}/>
                    <Form.Check type="checkbox" label="Як заробляти на казках / кейси."
                        name="like" id="q1_3" onChange={() => { changeMultiple(1, 3); }}/>
                    <Form.Check type="checkbox" label="Інформація для вчителів/ вихователів/ психологів."
                        name="like" id="q1_4" onChange={() => { changeMultiple(1, 4); }}/>
                    <Form.Check type="checkbox" label="Моє особисте життя / хобі / інтереси."
                        name="like" id="q1_5" onChange={() => { changeMultiple(1, 5); }}/>
                    <Form.Check type="checkbox" label="Життя в Іспанії."
                        name="like" id="q1_6" onChange={() => { changeMultiple(1, 6); }}/>
                    <Form.Check type="checkbox" label="Свій варіант:"
                        name="like" id="q1_7" onChange={() => { changeMultiple(1, 7); }}/>
                    <Form.Control value={text1} as="textarea" rows={2} maxLength={250} placeholder=""
                        onChange={(e) => { setText1(e.target.value); }}/>
                    <FormError error={formError === 3} text="Заповніть текстове поле або зніміть прапорець." />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formq2">
                    <Form.Label style={{fontWeight: "600"}}>Які проблеми зараз для вас актуальні (можете обрати декілька варіантів):</Form.Label>
                    <Form.Check type="checkbox" label="Проблеми з дитиною (налагодити сон, навчити правильно харчуватися, скорегувати поведінку, позбавити страхів, надати впевненості, тощо)."
                        name="prob" id="q2_1" onChange={() => { changeMultiple(2, 1); }}/>
                    <Form.Check type="checkbox" label="Шукаю натхнення, спілкування з творчими людьми, однодумцями."
                        name="prob" id="q2_2" onChange={() => { changeMultiple(2, 2); }}/>
                    <Form.Check type="checkbox" label="Питання з виданням власної книги (як написати книгу, що потрібно при виданні, як спілкуватися з видавництвами, як їх знаходити, як …, тощо)."
                        name="prob" id="q2_3" onChange={() => { changeMultiple(2, 3); }}/>
                    <Form.Check type="checkbox" label="Не знаю, як корисно проводити час з дитиною; шукаю поради щодо її розвитку та виховання."
                        name="prob" id="q2_4" onChange={() => { changeMultiple(2, 4); }}/>
                    <Form.Check type="checkbox" label="Шукаю додаткове джерело заробітку."
                        name="prob" id="q2_5" onChange={() => { changeMultiple(2, 5); }}/>
                    <Form.Check type="checkbox" label="Свій варіант:"
                        name="prob" id="q2_6" onChange={() => { changeMultiple(2, 6); }}/>
                    <Form.Control value={text2} as="textarea" rows={2} maxLength={250} placeholder=""
                        onChange={(e) => { setText2(e.target.value); }}/>
                    <FormError error={formError === 4} text="Заповніть текстове поле або зніміть прапорець." />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formq3">
                    <Form.Label style={{fontWeight: "600"}}>Як вам найзручніше отримувати інформацію з мого блогу?</Form.Label>
                    <Form.Check type="checkbox" label="Через сторіс."
                        name="inf" id="q3_1" onChange={() => { changeMultiple(3, 1); }}/>
                    <Form.Check type="checkbox" label="Через пости."
                        name="inf" id="q3_2" onChange={() => { changeMultiple(3, 2); }}/>
                    <Form.Check type="checkbox" label="В збережених хайлайтс."
                        name="inf" id="q3_3" onChange={() => { changeMultiple(3, 3); }}/>
                    <Form.Check type="checkbox" label="Гайди /чеклисти /книги."
                        name="inf" id="q3_4" onChange={() => { changeMultiple(3, 4); }}/>
                    <Form.Check type="checkbox" label="Спілкування в дірект."
                        name="inf" id="q3_5" onChange={() => { changeMultiple(3, 5); }}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTel" id="sectionPhone">
                    <Form.Label style={{fontWeight: "600"}}>Вкажіть номер вашого телефону:</Form.Label>
                    <PhoneInput defaultCountry="UA" placeholder="Телефону" onChange={setPhoneValue} style={{maxWidth: "520px"}} />
                    <FormError error={formError === 1} text="Номер телефону вказано невірно." />
                </Form.Group>
                <Form.Label style={{color: "darkgray", fontStyle: "italic"}}>Ця інформація залишиться конфіденційною і ніде не розповсюджуватиметься.</Form.Label>
                <div style={{textAlign: "center", marginTop: "8px", marginBottom: "16px"}}>
                    <FormError error={formError === 2} text="Не усі поля заповнені." />
                    <FormError error={formError === 5} text="Ви вже використовували цей номер телефону." />
                    <Button onClick={formSubmit}>ПІДТВЕРДИТИ</Button>
                </div>
            </Form></> :
            <div>
                <hr/>
                <p style={{fontWeight: "600"}}>Дякую за участь в опитуванні!</p>
                {downloadState < 2 && <>
                <p>Оберіть собі подарунок (<span style={{fontStyle: "italic"}}>натисніть на іконку, файл автоматично завантажиться на ваш пристрій</span>) 👇</p>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", pointerEvents: downloadState === 0 ? "all" : "none"}}>
                    <div className="buttonPresent" onClick={() => { downloadFile(1); }}>
                        <img src="/zhy/blog/file1.jpg" alt="" style={{width: "180px"}}/>
                        <p style={{marginTop: "6px", marginBottom: "0px"}}>ГАЙД: ЯК СТВОРИТИ МЕДИТАТИВНУ КАЗКУ</p>
                    </div>
                    <div className="buttonPresent" onClick={() => { downloadFile(2); }}>
                        <img src="/zhy/blog/file2.jpg" alt="" style={{width: "180px"}}/>
                        <p style={{marginTop: "6px", marginBottom: "0px"}}>КНИГА: ЧАРІВНА КНИГА (Як поводитися із незнайомцями на вулиці)</p>
                    </div>
                    <div className="buttonPresent" onClick={() => { downloadFile(3); }}>
                        <img src="/zhy/blog/file3.jpg" alt="" style={{width: "180px"}}/>
                        <p style={{marginTop: "6px", marginBottom: "0px"}}> ПУТІВНИК: ЯК ЗАРОБЛЯТИ НА КАЗКАХ</p>
                    </div>
                </div></>}
                <div id="progressSection">
                {isInProgress && <progress id="file" value={percentage} max="100" style={{width: "100%"}} />}
                </div>
                <p style={{marginTop: "16px"}}>Бажаю нам всім ще багато цікавих і незабутніх миттєвостей разом. Нехай наша спільнота розвивається.</p>
                <p>Діліться моїми постами, сторіс, подіями з тими, кого ви любите, про кого піклуєтеся та цінуєте.</p>
                <p>Казково обіймаю, ваша Анна Сапена!</p>
                <hr/>
                <p style={{fontWeight: "600"}}>Будемо разом:</p>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                    <a className="mylink" href="https://www.instagram.com/anna_sapena/" target="_blank" rel="noreferrer">
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src="/zhy/instagram.png" alt=""/>
                            Інстаграм
                        </div>
                    </a>
                    <a className="mylink" href="https://www.instagram.com/noovieworld" target="_blank" rel="noreferrer">
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src="/zhy/noovie.png" alt=""/>
                            Мої книги
                        </div>
                    </a>
                    <a className="mylink" href="https://www.magistrika.com" target="_blank" rel="noreferrer">
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src="/zhy/magistrika.png" alt=""/>
                            Школа казок
                        </div>
                    </a>
                    <a className="mylink" href="https://t.me/Kazki_AnnaSapena" target="_blank" rel="noreferrer">
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src="/zhy/channel.png" alt=""/>
                            Канал терапевтичних казок
                        </div>
                    </a>
                </div>
            </div>}
        </div>
        <div style={{backgroundColor: "ghostwhite", textAlign: "center", fontSize: "x-small", borderTop: "inset thin #f9c2b1"}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4px", marginBottom: "8px"}}>
                <Link className="mylink" to="/"><img src="/zhy/logo.png" alt="" style={{width: "84px"}}/>
                MAGISTRIKA</Link>
            </div>
            <p style={{margin: "0px", paddingBottom: "8px"}}>ШКОЛА ТЕРАПЕВТИЧНИХ КАЗОК ТА КРЕАТИВНОГО ПИСЬМЕННИЦТВА © 2022-2023.</p>
        </div>
    </div>
}

export default BlogForm1