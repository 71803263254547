import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/esm/Container";
import ImgWithFallback from "../components/ImgWithFallback";
import { constHOME, constPACKAGES, constPRICES } from "../components/courseConstants";
import { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input' 
import Instructions from "../components/Instructions";
import { useEffect, useRef } from "react";
import ReactPixel from 'react-facebook-pixel';
import React from "react";

function emailValidation(email) {
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return email && regex.test(email) === true;
}

function nameValidation(name) {
  const n = name ? name.trim() : "";
  return (n.length > 2) && (n.indexOf(' ') > 0) && (n.indexOf(' ') < n.length - 1) && (n.indexOf('&') < 0);
}

export function PayFormLogo() {
  return (
    <div style={{height: "90px"}}>
      <ImgWithFallback src="../zhy/logo.webp" fallbackSrc="../zhy/logo.png" className="smalllogo" />
    </div>
  );
}

function getIndex(plan) {
  return parseInt(plan.substring(4)) - 1;
}

function PayFormItem({plan}) {
  const id = getIndex(plan);
  return (
    <div style={{paddingLeft: "24px", paddingRight: "24px"}}>
      <Form.Text>
        <p><b>Курс:</b> ШКОЛА ДЛЯ БАТЬКІВ: КАЗКИ, ЩО ЛІКУЮТЬ</p>
        <p><b>Пакет:</b> {constPACKAGES[id]} ({constPRICES[id]}  грн.)</p>
      </Form.Text>
    </div>
  );
}

function FormError({error, text}) {
  return error ? 
    <p style={{color: 'red', fontSize: 'small'}}>{text}</p>
    : <></>
}

function PayForm({title}) {
    const { id } = useParams();
    const [formError, setFormError] = useState(0);  // 0: no error, 1: name, 2: email, 4: tel
    const [nameInput, setNameInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [commentInput, setCommentInput] = useState("");
    const myForm = useRef();

    useEffect(() => {
      document.title = title;
      ReactPixel.init("206064184014090");
      ReactPixel.pageView();
      ReactPixel.track('AddToCart', {content_ids: [constPACKAGES[getIndex(id)]], content_type: "product",
        value: constPRICES[getIndex(id)], currency: "UAH"});
    }, [title, id]);
    
    if (id !== "plan1" && id !== "plan2" && id !== "plan3") {
      return <Navigate replace to="/" />
    }
  
    const formSubmit = (evnt) => {
      evnt.preventDefault();
      // console.log(myForm.current.buttonId);
      var error = 0;
      if (!isValidPhoneNumber(phoneInput)) error += 4;
      if (!emailValidation(emailInput)) error += 2;
      if (!nameValidation(nameInput)) error += 1;
      if (error !== 0) {
        setFormError(error);
      } else {
        ReactPixel.track('InitiateCheckout', {content_ids: constPACKAGES[getIndex(id)], content_type: "product",
          num_items: "1", value: constPRICES[getIndex(id)], currency: "UAH"});
        const addr = constHOME + "purchase.php?plan=" + getIndex(id) + "&name=" +
          encodeURIComponent(nameInput) + "&email=" + 
          encodeURIComponent(emailInput) + "&phone=" +
          encodeURIComponent(phoneInput) + "&comment=" +
          encodeURIComponent(commentInput) + "&payMode=" + 
          myForm.current.buttonId.charAt(1);
        window.location.replace(addr);
      }
    }

    const setPhoneValue = (inputValue) => {
      const p = inputValue || "";
      setPhoneInput(p);
    }

    const setCommentValue = (event) => {
      setCommentInput(event.target.value || "");
    }

    return formError === -1 ?
      <Navigate replace to={constHOME + "purchase.php?name=" + nameInput + "&email=" + 
        emailInput + "&phone=" + phoneInput} /> :
      (
        <div style={{minHeight: "100vh", backgroundColor: "antiquewhite"}}>
        <Container style={{background: "white", paddingTop: "24px", paddingBottom: "24px", borderRadius: "8px", 
          transform: "translateY(34px)", boxShadow: "3px 4px 20px 0px #a38e73"}}>
        <PayFormLogo />
        <Form onSubmit={formSubmit} ref={myForm}>
          <PayFormItem plan={id} />
          <hr />
          <div style={{padding: "8px", backgroundColor: "#f6efe6", borderRadius: "8px"}}>
            <div style={{paddingLeft: "24px", paddingRight: "24px"}}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Прізвище та Ім'я*</Form.Label>
                <Form.Control type="text" placeholder="Прізвище та Ім'я" onChange={(evnt) => {
                  setNameInput(evnt.target.value)}} />
                <FormError error={(formError & 1) === 1} text="Необхідно вказати ім’я та прізвище" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Електронна пошта*</Form.Label>
                <Form.Control type="email" placeholder="Електронна пошта" onChange={(evnt) => {
                  setEmailInput(evnt.target.value)}} />
                <FormError error={(formError & 2) === 2} text="Електронна пошта вказана невірно" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formTel">
                <Form.Label>Номер мобільного телефону*</Form.Label>
                <PhoneInput placeholder="Телефону" onChange={setPhoneValue} />
                <FormError error={(formError & 4) === 4} text="Номер телефону вказано невірно" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCom">
                <Form.Label>Коментар (заповнюйте за бажанням)</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Коментар" onChange={setCommentValue} />
              </Form.Group>
            </div>
          </div>
          <hr />
          <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
            <div style={{maxWidth: "50%", fontSize: "small"}}>
              <Button className="buttonPay1" type="submit" id="p1" onClick={e => myForm.current.buttonId=e.target.id}
                style={{backgroundColor: "#f6b337", color: "black", borderRadius: "24px", padding: "12px"}}>
              Перейти до оплати
              </Button>
            </div>
            {false &&
            <Button className="buttonPay2" type="submit" id="p2" onClick={e => myForm.current.buttonId=e.target.id}>
            Оплатити через переказ<br/>за реквізитами на картку
            </Button>}
          </div>
        </Form>
        <Instructions />
        </Container>
        </div>
      );
}

/*
              <Button className="buttonPay1" type="submit" id="p1" onClick={e => myForm.current.buttonId=e.target.id}>
              Оплатити через Приват24 &nbsp;<img width="26px" src="../zhy/privat24.png" alt=""/>&nbsp;,<br/>кредитною карткою
              &nbsp;<img width="30px" src="../zhy/card.png" alt=""/>&nbsp; або терміналом
              </Button>
*/

export default PayForm;